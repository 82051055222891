
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































.app-copy-text {
  .link {
    width: calc(100% - 40px);
  }
}
