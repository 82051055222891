@import "../common/clb-var.scss";

// ======== STATES ===========
@mixin linkStates($--clb-link-color, $--clb-visited, $--clb-hover, $--clb-active) {
  color: $--clb-link-color;

  &:visited {
    color: $--clb-visited;
  }

  &:hover {
    text-decoration: $--clb-hover;
    color: $--clb-hover-color;
    cursor: pointer;
  }

  &:active {
    color: $--clb-active;
  }
}

// ======== TEXT CONTENT ===========
@mixin dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin line-clamp($numLines : 1, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;

  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('em');
}

// ========= EL INPUT ======
@mixin el-input {
  .el-input__inner {
    @apply tw-transition tw-h-auto tw-py-space-1px;

    &:focus {
      @apply tw-h-auto tw-py-space-1px;
    }
  }

  .el-input__prefix,
  .el-input__suffix {
    @apply tw-flex tw-items-center;
  }

  .el-input__clear {
    @apply tw-hidden;
  }

  &.el-input--prefix .el-textarea__inner,
  &.el-input--prefix .el-input__inner, {
    @apply tw-p-1 tw-pl-layout-2 tw-transition tw-h-auto;

    &:focus {
      @apply tw-p-1 tw-pl-layout-2 tw-h-auto;
    }
  }

  &.el-input--suffix .el-textarea__inner,
  &.el-input--suffix .el-input__inner {
    @apply tw-pr-layout-3;
  }

  &.el-input.is-disabled .el-input__inner {
    @apply tw-text-[#a1a1a1];
  }
}

// ========= MAIN LAYOUT ======

@mixin main-layout {
  transition: padding $--clb-transition-time ease;
  padding: $--clb-layout-2 $--clb-mobile-padding;
  background: $--clb-color-primary__white;
  border-radius: $--clb-border-radius;

  @apply sm:tw-mx-layout-3 sm:tw-p-layout-4 md:tw-py-layout-3 md:tw-px-layout-2;

  @media (min-width: 2200px) {
    @apply tw-max-w-screen-2xl tw-mx-auto;
  }
}

@mixin page-layout {
  @apply tw-p-layout-1 sm:tw-p-layout-2 md:tw-p-layout-3;

  @media (min-width: 2200px) {
    @apply tw-max-w-screen-2xl tw-mx-auto tw-px-0;
  }
}

@mixin stage-layout {
  @apply
    tw-bg-white tw-rounded
    tw-p-layout-1 sm:tw-p-layout-2 md:tw-p-layout-3
    md:tw-m-layout-3
  ;

  @media (min-width: 2200px) {
    @apply tw-max-w-screen-2xl tw-mx-auto;
  }
}

@mixin pagination {
  margin-top: $--clb-space-2;
  padding: 0;

  .el-pager {
    .number {
      color: $--clb-color-grey__grey;
      box-shadow: 0 2px 0 rgba(white, 0);
      transition: $--clb-hover-transition;

      &:hover {
        color: $--clb-color-grey__grey-dark;
      }

      &.active {
        color: $--clb-color-primary;
      }
    }
  }
}

// ======== FONTS ===========

// ======== BUTTON ===========
@mixin clb-button {
  border: $--clb-btn-border-none;
  background: theme('colors.jb-indigo.DEFAULT');
  color: $--clb-color-primary__white;
  font-size: $--clb-font-size-base;
  font-weight: $--clb-font-weight__bold;
  width: $--clb-btn-width;
  transition: $--clb-hover-transition;
  padding: 0 theme('spacing.space-4');
  transition-property: color, background, box-shadow, border-color, transform;
  border-radius: $--clb-border-radius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;

  &.mini {
    height: 40px;
    font-weight: 500;
    font-size: $--clb-font-size-sm;
  }

  &.micro {
    height: 28px;
    font-weight: 400;
    font-size: $--clb-font-size-xs;
  }

  &.positive {
    background: $--clb-color-pink;
  }

  &.success {
    background: #81cf74;
  }

  &.green {
    background: $--clb-color-green;
  }

  &.red {
    background: $--clb-color-red;
  }

  &.warning {
    background: #f0bf19;
  }

  &.danger {
    background: #e31717;
  }

  &.secondary {
    color: theme('colors.jb-indigo.DEFAULT');
    border: 2px solid theme('colors.jb-indigo.DEFAULT');
    background: white;
  }

  &.text {
    background: none;
    border: none;
    color: $--clb-color-grey__grey-dark;
  }

  &.link {
    color: $--clb-color-primary__dark;
    border: none;
    background: white;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }

  &:disabled:not(.text) {
    color: $--clb-color-grey__grey-light;
    background: $--clb-color-grey__grey-lighter;
    outline: transparent;

    &.link {
      color: $--clb-color-grey__grey-dark;
      text-decoration: underline;
      outline: transparent;
      background: none;
      border: none;
    }

    &.secondary {
      color: $--clb-color-grey__grey-light;
      outline: transparent;
      background: none;
      border: 2px solid $--clb-color-grey__grey-lighter;
    }
  }

  &:hover:not(:disabled) {
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.18);
    background: theme('colors.jb-indigo.dark');
    cursor: pointer;

    &.positive {
      background: #f35289;
    }

    &.success {
      background: #6ccc5c;
    }

    &.warning {
      background: #dead19;
    }

    &.green {
      background: $--clb-color-green-darker;
    }

    &.red {
      background: $--clb-color-red-darker;
    }

    &.danger {
      background: #c21717;
    }

    &.secondary {
      background: $--clb-color-secondary__light;
    }

    &.text {
      box-shadow: none;
      background: none;
      cursor: pointer;
    }

    &.link {
      background: none;
      box-shadow: none;
      color: $--clb-color-primary__dark;
      font-weight: $--clb-font-weight__medium;
    }
  }

  &:hover:active:not(:disabled):not(.text) {
    background: theme('colors.jb-indigo.dark');
    outline: transparent;
    transform: translateY(0);

    &.positive {
      background: $--clb-color-pink;
    }

    &.success {
      background: #81cf74;
    }

    &.warning {
      background: #f0bf19;
    }

    &.danger {
      background: #e31717;
    }

    &.green {
      background: $--clb-color-green-darker;
    }

    &.red {
      background: $--clb-color-red-darker;
    }

    &.secondary {
      background: white;
      color: $--clb-color-primary__dark;
    }

    &.link {
      background: none;
      box-shadow: none;
      color: $--clb-color-primary__dark;
      font-weight: $--clb-font-weight__bold;
    }
  }

  &--block {
    width: 100%;
  }

  &--loading {
    padding: $--clb-space-3 $--clb-space-4;

    span {
      flex: 1;
    }
  }

  &__loader {
    position: relative;
    margin-right: $--clb-space-1;

    .el-loading-spinner {
      .circular {
        height: 20px;
        width: 20px;
      }

      .path {
        stroke-width: 5;
        stroke: $--clb-color-primary__white;
      }
    }
  }

  .el-icon-arrow-right {
    position: relative;
    top: 1px;
  }
}

// ======== NO-SHADOW-HOVER ===========
@mixin no-shadow-hover {
  box-shadow: $--clb-no-shadow-hover;
  transform: $--clb-transform-hover;
}

// ======== EASE-HOVER ===========
@mixin ease-hover {
  transform: scale(1.1);
  text-decoration: none;
  cursor: pointer;
}

// ======== HEADER-ANIMATIONS ===========
@mixin leaving-pop-animation() {
  @keyframes leaving-pop {
    0% {
      transform: scale(1) translateZ(0);
      opacity: 1;
    }

    100% {
      transform: scale(1.1) translateZ(0);
      opacity: 1;
    }
  }
}

@mixin entering-pop-animation() {
  @keyframes entering-pop {
    0% {
      transform: scale(1.1) translateZ(0);
      opacity: 0;
    }

    100% {
      transform: scale(1) translateZ(0);
      opacity: 1;
    }
  }
}

@mixin responsive-grid($max-columns) {
  $size-map: (
    $--xs: xs,
    $--sm: sm,
    $--md: md,
    $--lg: lg,
    $--xl: xl
  );

  $size-list: $--xs $--sm $--md $--lg $--xl;
  $columns: $max-columns;

  display: grid;
  grid-column-gap: $--clb-space-3;
  grid-row-gap: $--clb-layout-2;

  @each $size in $size-list {
    @media (min-width: $size) {
      @for $index from 1 through $columns {
        &.#{map-get($size-map, $size)}-#{$index} {
          grid-template-columns: repeat($index, 1fr);
        }
      }
    }
  }
}

@mixin xs-only {
  @media only screen and (max-width: $--xs) {
    @content;
  }
}

@mixin xs-down {
  @media only screen and (max-width: #{$--sm - 1}) {
    @content;
  }
}

@mixin xs-up {
  @media only screen and (min-width: $--xs) {
    @content;
  }
}

@mixin sm-up {
  @media only screen and (min-width: $--sm) {
    @content;
  }
}

@mixin sm-and-down {
  @media only screen and (max-width: #{$--md - 1}) {
    @content;
  }
}

@mixin md-up {
  @media only screen and (min-width: $--md) {
    @content;
  }
}

@mixin lg-up {
  @media only screen and (min-width: $--lg) {
    @content;
  }
}

@mixin mobile-landscape {
  @media screen
    and (max-width: 820px)
    and (max-height: 480px)
    and (orientation: landscape) {
    @content;
  }
}
