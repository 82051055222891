
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        












































.referral-copy-link {
  background-color: theme('colors.jb-indigo.DEFAULT');

  @include sm-up {
    --gradient-start: theme('colors.jb-indigo.darker');
    --gradient-end: theme('colors.jb-indigo.DEFAULT');

    background: linear-gradient(-45deg, var(--gradient-start) 24%, var(--gradient-end) 24%);
  }

  @include lg-up {
    --gradient-start: theme('colors.jb-indigo.darker');
    --gradient-end: theme('colors.white');

    background: linear-gradient(-45deg, var(--gradient-start) 33%, var(--gradient-end) 30%);
  }
}
